.memorandum-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5); /* Transparent background */
    padding: 20px;
    border-radius: 10px;
  }
  
  .caption h2 {
    font-size: 2em;
    margin: 10px 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  }
  
  .image-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image {
    width: 50%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
  }
  
  .image.left {
    /* clip-path: polygon(0 0, 100% 0, 0 100%); */
    left: 0;
    width: 50%;
  }
  
  .image.right {
    /* clip-path: polygon(100% 0, 100% 100%, 0 100%); */
    right: 0;
    width: 50%;

  }
  