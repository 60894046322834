
:root{
  --navbar-bg:#002b2a94;
  --navbar-bg-hover:#002b2a94 ;
  --menu-color:#fff;
  --menu-link-hover:#ffb300;
}

body.active-menu {
  overflow-y: hidden;
}

header {
  height: 100vh;
  background: url('../../img/headerbg.jpg') center/cover;
  background-attachment: fixed;
}

header .ul {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  margin: 0 !important;
}

header li {
  list-style-type: none;
  margin: 0 20px;
}

header a {
  color: var(--menu-color) !important;
  text-decoration: none !important;

}

header a:hover {
  color: var(--menu-link-hover) !important;
}

header .bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
  background-color:var(--menu-color)
}

header .hamburger {
  display: none;
  cursor: pointer;
  background-color: var(--navbar-bg);
  padding: 10px;
  border-radius: 10px;
  margin-left: 10px;
  z-index: 2;
}

header nav.navbar {
  min-height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  transition: all .4s;
  position: fixed;
  width: 100%;
  z-index: 1;
}

header nav.navbar:hover {
  background-color: var(--navbar-bg);
}

header .nav-branding { 
  display: flex;
  flex-direction: row;
  align-items: center;
  row-gap: 8px;
  font-size: 1.7rem;
  font-weight:700;
}

.nav-branding img{
  max-width: 100px;
}

header .nav-link{
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 18px !important;
  font-weight: 500 !important;
  letter-spacing: 1px;
  transition: 0.5s;
}

header .nav-link:hover {
  color: var(--menu-link-hover) !important;
  text-decoration-skip-ink: none;
}

header .active {
  font-weight: bold;
  opacity: 1;
  color: var(--menu-link-hover) !important;
}

header #languages {
    font-size: 18px !important;
    margin-right: 0;
    text-transform: capitalize;
}
.dropbtn {
  background-color: #ffffff00;
  border: none;
  cursor: pointer;
  
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;

}

.dropdown-content a {
  text-align: center;
  padding: 0 8px;
  width: 40px;
  text-decoration: none;
  display: block;
  background-color: #f1f1f12f;

}

.dropdown-content a:hover {
}

.dropdown:hover .dropdown-content {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}


header .nav-item-link {
  display: flex;
  flex-direction: row;
  gap: 28px;
  justify-content: center;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  header .navbar {
    padding: 30px 0 30px 25px;
  }
  header .nav-item-link {
    align-items: center;
    text-align: center;
  }
} 

@media only screen and (max-width: 480px) {
  header .nav-item-icon {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media only screen and (max-width: 604px) {
  .header-title {
    font-size: 52px !important;
  }
  header {

    background: url('../../img/headerbgmob.jpg') center/cover;
    background-position: right;

  }
}

@media only screen and (max-width: 362px) {
  .header-title {
    font-size: 36px !important;
  }

  .header-subtitle {
    font-size: 12px !important;
  }
}
.header-title{
  align-self: end;
  padding-top: 150px;
}
@media only screen and (max-width: 1200px) {
  header .navbar {
    padding: 30px 0 30px 25px;
  }

  header .nav-item-link {
    align-items: center;
    text-align: center;
  }
}

@media only screen and (max-width: 992px) {
  header{
    
    background-attachment: inherit;
  }
  header .ul {
    flex-direction: column;
    gap: 20px;
  }

  header .nav-link {
    font-size: 20px;
  }

  header .nav-item-link {
    flex-direction: column;
    align-items: baseline;
  }

  li:nth-child(4) {
    padding-left: 0;
  }

  header .navbar {
    width: 100%;
    padding-right: 10px;
  }
  header nav.navbar:hover { 
    background-color: #fff0;
  }

  header .hamburger {
    background-color: #ffffff00;
    display: block;
  }

  header .hamburger.active .bar:nth-child(2) {
    opacity: 0;;
  }

  header .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  header .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  header .nav-menu {
    display: flex;
    position: fixed;
    left: -100%;
    top: 0;
    gap: 0;
    flex-direction: column;
    background-color: #051f1f;
    width: 100%;
    transition: 1s;
    z-index: 1;
    padding: 35px 20px;
  }

  header .nav-item {
    margin: 8px 0;
    text-align: left;
  }

  header .nav-menu.active {
    left: 0;
  }
}

header h6 {
  text-align: center;
  text-transform: uppercase;
}

header h1 {
  text-align: center;
  text-transform: capitalize;
  font-weight: 700 !important;
  font-size: 96px !important;
}

header .header {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  align-items: center;
}

header {
  position: relative;
}

header .bottom{
  position: absolute;
  width: 100%;
  bottom: 30px;
  display: flex;
  align-items: center;  
}

header .mouse {
	width: 60px;
	height: 60px;
	position: relative;
  left: 44%;
	animation: down 1.5s infinite;
	-webkit-animation: down 1.5s infinite;
	&::before {
		content: '';
		position: absolute;
		top: 15px;
		left: 18px;
		width: 18px;
		height: 18px;
		border-left: 2px solid #666;
  	border-bottom: 2px solid #666;
		transform: rotate(-45deg);
	}
}

@media only screen and (max-width: 992px) {
  header .mouse {
    display: none;
    &::before {display: none;}
  }

  header li {
    margin-left: 0;
  }
}

@keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

@-webkit-keyframes down {
	0% {
		transform: translate(0);
	}
	20% {
		transform: translateY(15px);
	}
	40% {
		transform: translate(0);
	}
}

header .socials {
  font-size: 25px !important;
  padding: 0 15px;
}

header .socials > a > i {
  margin: 0 10px;
  opacity: .4;
  transition: all .5s;
}

header .socials > a > i:hover {
  opacity: 1;
}