:root {
  --divbg:#002b2a;
}

.appointment {
  background-color: var(--divbg);
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appointment > div {
  width: 90%;
  padding: 100px 40px;
  margin: auto;
  border-radius: 40px;
  background: #002b2a;
  box-shadow:  20px 20px 60px #002524, -20px -20px 60px #003130;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.appointment h2 {
  text-transform: capitalize;
  margin-bottom: 16px;
}

.appointment p {
  font-size: 16px;
  width: 80%;
}

.appointment .card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  width: 450px;
  display: flex;
  flex-direction: column;
}

.appointment .title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.appointment .form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.appointment .group {
  position: relative;
}

.appointment .form .group label {
  font-size: 14px;
  color: rgb(132, 138, 138);
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: #ffffff;
  transition: all .3s ease;
}

.appointment .form .group input,
.appointment .form .group textarea {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  outline: 0;
  width: 100%;
  background-color: transparent;
}

.appointment .form .group input:placeholder-shown+ label, .form .group textarea:placeholder-shown +label {
  top: 10px;
  background-color: transparent;
}

.appointment .form .group input:focus,
.appointment .form .group textarea:focus {
  border-color: var(--divbg);
}

.appointment .form .group input:focus+ label, .appointment .form .group textarea:focus +label {
  top: -10px;
  left: 10px;
  background-color: #ffffff;
  color: var(--divbg);
  font-weight: 600;
  font-size: 14px;
}

.appointment .form .group textarea {
  resize: none;
  height: 100px;
}

.appointment .form button {
  background-color: var(--divbg);
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.appointment .form button:hover {
  background-color: #224443;
}

@media only screen and (max-width: 992px) {
  .appointment > div {
    flex-direction: column;
    justify-content: center;
    padding: 60px 30px;
  }

  .appointment h2 {
    text-align: center;
  }

  .appointment p {
    width: 100%;
  }
  
  .appointment .card {
    max-width: 100%;
  }
}

@media only screen and (max-width: 512px) {
  .appointment p {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 342px) {
  .appointment > div {
    padding: 40px 20px;
  }

  .appointment h2 {
    font-size: 20px !important;
  }

  .appointment .form .group label {
    font-size: 10px;
  }

  .appointment .form .group input:focus+ label, .appointment .form .group textarea:focus +label  {
    font-size: 12px;
  }
}
