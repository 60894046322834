.fabricsInfo a {
    font-size: 20px !important;
    text-align: center;
    text-decoration: none;
    color: white !important;
    transition: all .25s;
}

.fabricsInfo a:hover {
    color: #ffb300 !important;
}

.fabricsInfo h2 {
    font-size: 45px !important;
}

.fabrics {
    width: 31%;
}

.fabrics-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
}

.fabrics > a:hover > i {
    color: #ffb300;
}

.fa-link {
    color: #fff;
    transition: 0.3s;
}

@media only screen and (max-width: 992px) {
    .fabrics {
        width: 46%;
    }

    .fabricsInfo h2{
        font-size: 32px !important;
    }
}

@media only screen and (max-width: 768px) {
    .fabricsInfo a {
        font-size: 16px !important;
    }
}

@media only screen and (max-width: 558px) {
    .fabricsInfo h2 {
        font-size: 28px !important;
    }
    
    .fabrics-row {
        flex-direction: column;
        align-items: center;
        margin: 0 20px;
    }

    .fabrics {
        width: 100%;
    }
}

@media only screen and (max-width: 438px) {
    .fabricsInfo h2 {
        font-size: 22px !important;
    }

    .fabricsInfo a, .fabricsInfo i {
        font-size: 13px !important;
    }
}