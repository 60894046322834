.blueSection {
    width: 100%;
}

.blueSection > div {
    display: flex;
}

.blueSection > div > div {
    width: calc(100%/3);
}

.blueSection img {
    width: 100%;

}
.blueSection .photoLink {
    background: linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.2)),url('../../img/suits/blue/9.jpg') center/cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blueSection div.photoLink > p {
    font-size: 30px;
    color: white !important;
    padding-top: 70px;
    text-transform: capitalize;
    text-align: center;
    font-weight: 700;
}

.blueSection div.photoLink > a {
    margin-top: 20px;
    border-radius: 20px;
    padding: 5px 30px;
    border: 2px solid white;
    text-decoration: none;
    font-weight: 600;
    transition: all .25s;
}

.blueSection div.photoLink > a {
    color: white !important;
}

.blueSection div.photoLink > a:hover {
    background-color: white;
    color: #4f7d7c !important;
}

@media only screen and (max-width: 992px) {
    .blueSection div.photo {
        display: none;
    }

    .blueSection > div > div{
        width: 100%;
        height: 50vh;
    }
}

@media only screen and (max-width: 442px) {
    .photoLink > p {
        font-size: 22px !important;
    }
}