.section1 {
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center ;
    background-image: linear-gradient(#002b2a,#113737);
}

.section1 > h2, .section1 > p {
    margin: 30px 70px;
}

.section1 > h2 {
    font-size: 50px;
    font-weight: 500;
}
.section1 > p {
    font-size: 20px;
}

@media only screen and (max-width: 992px) {
    .section1 {
        height: inherit;
    }

    .section1 > h2 {
        font-size: 32px;
    }
}

@media only screen and (max-width: 576px) {
    .section1 > h2, .section1 > p {
        margin: 25px 50px !important;
    }       

    .section1 > h2 {
        font-size: 28px !important;
    }

    .section1 > p {
        font-size: 16px !important;
    }
}

@media only screen and (max-width: 380px) {
    .section1 > h2 {
        font-size: 20px !important;
    }

    .section1 > p {
        font-size: 14px !important;
    }
}
