.section3 {
    background-image: linear-gradient(#002b2a,#143635);
} 

.section3div {
    display: flex;
    justify-content: center;
    align-items: stretch;    
    width: 100%;
}

.section3div > div {
    width: calc(100%/3);
}

.section3div img {
    width: 100%;
    height: 100%;
}

.section3div > .photoLink {
    background: url('../../img/russo6.jpg') center/cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section3div div.photoLink > a {
    margin-top: 20px;
    border-radius: 20px;
    padding: 5px 30px;
    text-decoration: none;
    text-align: center;
    font-weight: 600;
    background-color: white;
    border: 2px solid white;
    color: #002b2a !important;
    transition: all .25s;
}

.section3div div.photoLink > a:hover {
    border-color: #002b2a;
    background-color: #002b2a;
    color: white !important;
}

@media only screen and (max-width: 992px) {
    .section3div {
        flex-direction: column;
    }

    .section3div .photo {
        display: none;
    }

    .section3div > div {
        width: 100%;
        height: 60vh;
    }
}

@media only screen and (max-width: 348px) {
    .section3div > .photoLink > a { 
        font-size: 14px;
        width: 90%;
        border-radius: 20px;
        padding: 5px 15px !important;
    }
}