.stores{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.stores iframe{
    margin-top: -60px;
    width: 100%;
    height: 500px;
}
@media only screen and (max-width: 992px) {
    .stores iframe{
        height: 400px;
    }
}

