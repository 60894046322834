.fabricsIntro > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fabricsIntro .photo1 {
    width: 70%;
    min-height: 70vh;
    background:url('../../img/fabric1.jpg') bottom/cover ;
}

.fabricsIntro .photo2 {
    width: 70%;
    min-height: 70vh;
    background:url('../../img/fabric2.jpg') bottom/cover ;
}
 
.fabricsIntro .desc1 {
    width: 50%;
    min-height: 70vh;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(8px);
    margin-left: -200px;
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    padding: 0 30px;   
}

.fabricsIntro .desc2 {
    width: 50%;
    min-height: 70vh;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(8px);
    margin-right: -200px;
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    padding: 0 30px;   
}

.fabricsIntro h2 {
    font-size: 50px !important;
    color: #002b2a !important;
    text-align: center !important;
}

.fabricsIntro p {
    font-size: 18px !important;
    text-align: left;
}

@media only screen and (max-width: 992px) {
    .fabricsIntro > div{
        flex-direction: column;
    }

    #fabricsIntrodiv2 {
        flex-direction: column-reverse;
    }

    .fabricsIntro .photo1, .fabricsIntro .photo2 {
        width: 100%;
        min-height: 40vh;
    }

    .fabricsIntro .desc1, .fabricsIntro .desc2 {
        width: 100%;
        margin: 0;
        min-height: 40vh;
    }
}

@media only screen and (max-width: 768px) {
    .fabricsIntro h2 {
        font-size: 34px !important;
    }
    
    .fabricsIntro p {
        font-size: 16px !important;
    }
}

@media only screen and (max-width: 438px) {
    .fabricsIntro h2 {
        font-size: 22px !important;
    }
    
    .fabricsIntro p {
        font-size: 12px !important;
    }
}