@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Sacramento&display=swap');

/* width */

body {
  background-color: #002b2a !important;
  font-family: Lora, serif !important;
  letter-spacing: 0.7px;
}

::selection {
  background-color: #ffb300;
  color: #002b2a;
}

::moz-selection {
  background-color: red;
  color: #fff;
}

::-webkit-scrollbar {
  width:0;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #ffffff;
  padding: 5px 10px;
  font-size: 22px;
  color: #002b2a;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  transition: all .5s;
}

.back-to-top:hover {
  background: #1b414083;
  color: white;
}