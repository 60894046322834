.footer-mario, 
.footer-title,
.footer-text,
.footer-ul,
.footer-contact,
.footer-email,
.footer-tel,
.footer-copyright,
.footer-ul > li,
.footer-contact > li {
    color: white!important;
    padding: 0;
    margin: 0;
    list-style: none;
}

.footer-mario:hover,
.footer-socialmedia:hover, 
.footer-tel > a:hover, 
.footer-email > a:hover,
.footer-ul > li > a:hover {
    color: #ffb300 !important;
}

.footer-mario,
.footer-socialmedia, 
.footer-tel > a, 
.footer-email > a,
.footer-copyright > a,
.footer-ul > li > a {
    transition: color 0.3s;
}

footer {
    background-color: #051f1f;
}

a {
    text-decoration: none !important;
}

.footer-cols {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
}

.footer-mario {
    font-size: 32px;
}

.footer-text {
    color: #c5c5c5;
    font-size: 16px;
    margin-top: 10px;
}

.footer-title {
    color: #fff;
    font-size: 20px;
}

.footer-ul {
    margin-top: 16px;
}

.footer-ul > li > a {
    font-size: 16px;
    color: #c5c5c5 !important;
}

.footer-ul > li:not(:last-child), .footer-contact > li:not(:last-child) {
    padding-bottom: 12px;
} 

.footer-socialmedia {
    font-size: 20px;
}

.footer-tel, .footer-email {
    color: #fff;
}

.footer-tel > a, .footer-email > a {
    font-size: 14px;
    color: #c5c5c5 !important;
}

.footer-bottom {
    width: 100%;
    border-top: 2px solid #ffffff1a;
    margin-top: 90px;
    padding-bottom: 50px;
}

.footer-contact {
    margin-top: 16px;
}

.footer-copyright {
    padding-top: 36px;
}
.footer-copyright > a{
    color: #c5c5c5 !important;
}

.footer-copyright > a:hover {
    color: #25a1b0 !important;
}

@media screen and (max-width: 992px) {
    .footer-cols {
        padding: 0;
        flex-direction: column !important;
        gap: 60px;
    }

    .footer-col-1 {
        padding-top: 50px;
    }
}

@media screen and (max-width: 422px) {
    .footer-mario {
        font-size: 26px;
    }

    .footer-text {
        font-size: 14px;
    }

    .footer-title {
        font-size: 16px;
    }

    .footer-ul > li > a {
        font-size: 14px;
    }

    .footer-tel, .footer-email {
        font-size: 14px;
    }

    .footer-tel > a, .footer-email > a {
        font-size: 12px;
    }

    .footer-copyright {
        font-size: 14px;
    }

    .footer-bottom {
        margin-top: 50px;
    }

    .footer-socialmedia {
        font-size: 17px;
    }
}
