.gallery-header,
.gallery-text {
    padding: 0;
    margin: 0;
}

.gallery {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
}

.gallery-header {
    color: #fff;
    font-size: 32px;
    margin: 60px 0 6px 0;
    letter-spacing: 3px;
}

.gallery-text {
    color: #fff;
    font-size: 14px;
    margin-bottom: 15px;
    letter-spacing: 1.5px;
}   

.gallery-line {
    width: 80px;
    height: 2px;
    background-color: #a2a2a2;
}

.gallery-imgs {
    width: 95%;
    display: grid;
    justify-content: center;
    place-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px  ;
    margin: 100px 0;
}

.gallery-imgs:hover > :not(:hover){
    filter: grayscale(500%);
}

.img-div {
    transition: .5s;
    width: 100%;
    height: 440px;
    overflow: hidden;
    object-fit: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.img-div:hover {
    box-shadow: 0px 0px 8px 3px rgba(176, 176, 176, 0.686);
}  

.gallery-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .4s;
    cursor: pointer;
}

.gallery-img:hover {
    transform: scale(1.05);
}

.modal-bg {
    background-color: #1c1c1ca6;
}

.gallery-modal {
    overflow: hidden;
    width: 500px !important;
    height: 100vh;
    width: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    outline: none;
}

.modal-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.fa-x {
    font-size: 26px;
    position: absolute;
    z-index: 2000000 !important;
    top: 30px;
    right: 30px;
    color: #747474b8;
    cursor: pointer;
    transition: .3s;
}

.fa-x:hover {
    color: #fab515d8;
}

@media screen and (max-width: 992px) {
    .gallery {
        padding: 0 !important;
    }

    .gallery-imgs {
        width: 100%;
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 702px) {
    .gallery-imgs {
        width: 75%;
        grid-template-columns: 1fr;
        margin-top: 70px !important;
    }

    .img-div {
        height: 500px;
    }
}

@media screen and (max-width: 462px) {
    .gallery-imgs {
        width: 80%;
    }

    .img-div {
        height: 430px;
    }

    .gallery-modal {
        width: 100vw !important;
        height: 70vh;
    } 
}

@media screen and (max-width: 332px) {
    .img-div {
        height: 350px;
    }

    .gallery-header {
        font-size: 26px;
    }

    .gallery-text {
        font-size: 12px;
    }
}