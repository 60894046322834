:root{
    --preloader-image:url('../../img/sparta.png')
}

.preloader-container{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: linear-gradient(to bottom right,#002b2a  , #203e3e );
}

.preloader{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    -webkit-mask-image: var(--preloader-image);
    -webkit-mask-size: contain;
    -webkit-mask-position: center;
    -webkit-mask-repeat: no-repeat;
    animation-name: example;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background: linear-gradient(to top, #ffb300 50%, #ffffff 50%);
        background-size: 100% 200%;
}

@keyframes example {
    0%   {
        background-position:  top;
        
        }
    100% {background-position: bottom}
    
  }
