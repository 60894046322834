.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 120px 0;
}

.error-msg, .error-warning, .backto-home {
    color: #fff;
    text-align: center;
}

.backto-home {
    color: #fff;
    transition: 0.3s; 
}

.backto-home:hover {
    color: #fab515d8;
}

.error-warning {
    font-size: 42px;
    margin-bottom: 50px;
}

.error-msg {
    font-size: 26px;
    margin-bottom: 30px;
}

@media screen and (max-width: 992px) {
    .error-msg {
        font-size: 22px;
    }
}

@media screen and (max-width: 532px) {
    .error-warning {
        font-size: 30px;
    }

    .error-msg {
        font-size: 16px;
    }
}